import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import "./css/base.css";
import "./css/index.css";
import { AnimatePresence } from "framer-motion";

import AlertTemplate from "./components/AlertTemplate";
import Accommodation from "./pages/Accommodation";
import Menu from "./pages/Menu";
import PreWedding from "./pages/PreWedding";
import useTitle from "./hooks/useTitle";
import Rsvp from "./pages/Rsvp";
import Transportation from "./pages/Transportation";
import Venue from "./pages/Venue";
import Index from "./pages/Index";
import Invitation from "./pages/Invitation";

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  const [title, setTitle] = useState("en");
  const location = useLocation();

  useTitle({ title });

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={<Index language="gr" setTitle={setTitle} />}
          />
          <Route
            path="/gr"
            element={<Index language="gr" setTitle={setTitle} />}
          />
          <Route
            path="/en"
            element={<Index language="en" setTitle={setTitle} />}
          />

          <Route
            path="/en/invitation"
            element={<Invitation language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/invitation"
            element={<Invitation language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/accommodation"
            element={<Accommodation language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/accommodation"
            element={<Accommodation language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/menu"
            element={<Menu language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/menu"
            element={<Menu language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/pre-wedding"
            element={<PreWedding language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/pre-wedding"
            element={<PreWedding language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/rsvp"
            element={<Rsvp language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/rsvp"
            element={<Rsvp language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/transportation"
            element={<Transportation language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/transportation"
            element={<Transportation language="gr" setTitle={setTitle} />}
          />

          <Route
            path="/en/venue"
            element={<Venue language="en" setTitle={setTitle} />}
          />
          <Route
            path="/gr/venue"
            element={<Venue language="gr" setTitle={setTitle} />}
          />

          {/* catch all */}
          <Route
            path="*"
            element={<Menu language="en" setTitle={setTitle} />}
          />
        </Routes>
      </AnimatePresence>
    </AlertProvider>
  );
}

export default App;
