import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/invitation.css";
import { Link } from "react-router-dom";

const Invitation = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("Invitation");
    } else {
      setTitle("Προσκλητήριο");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/en/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_invitation.png"
              alt="Invitation Icon"
            />
            <img
              className="invitation_image"
              src="../images/invitation.png"
              alt="Invitation"
            />
            <div className="ibans fccc">
              For whoever wishes
              <div className="iban">
                Alpha Bank IBAN: GR0901402090209002310018422
              </div>
              <div className="iban">ING IBAN: NL48 INGB 0700 3084 07</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/gr/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_invitation.png"
              alt="Invitation Icon"
            />
            <img
              className="invitation_image"
              src="../images/invitation.png"
              alt="Invitation"
            />
            <div className="ibans fccc">
              Για όσους επιθυμούν
              <div className="iban">
                Alpha Bank IBAN: GR0901402090209002310018422
              </div>
              <div className="iban">ING IBAN: NL48 INGB 0700 3084 07</div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Invitation;
