import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/pre-wedding.css";
import { Link } from "react-router-dom";

const PreWedding = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("Pre-Wedding Party");
    } else {
      setTitle("Προεόρτια");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/en/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_pre_wedding.png"
              alt="PreWedding Icon"
            />
            <div className="page_info">
              On Friday 30 June from 16:00, there will be a small celebration in
              beach bar Tsimpaei? in Alikis beach, Aigio, in order for everybody
              to meet, relax and enjoy. Swimwear necessary!
              <br />
              <br />
              In case of adverse weather conditions due to the unpredictable
              winds of the area, the location will change after notifying
              everybody.
            </div>
            <div className="frcc google_link_row">
              <img
                className="google_maps_icon"
                src="../images/google_maps.png"
                alt="Google Maps Icon"
              />
              <Link
                to="https://goo.gl/maps/bczGhLicyBwQNtN57?coh=178573&entry=tt"
                className="google_link"
                target="_blank"
              >
                See location in Google maps
              </Link>
            </div>

            <img
              className="pre_wedding_image"
              src="../images/pre_wedding_image.jpg"
              alt="PreWedding Icon"
            />
          </div>
        </div>
      ) : (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/gr/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_pre_wedding.png"
              alt="PreWedding Icon"
            />
            <div className="page_info">
              Την Παρασκευή 30 Ιουνίου από τις 16:00, θα υπάρξει μια μικρή
              γιορτή στο beach bar Τσιμπάει; στην παραλία Αλυκής, Αίγιο, ώστε
              όλοι να γνωριστούμε, να χαλαρώσουμε και να περάσουμε καλά.
              Απαραίτητο το μαγιό σας.
              <br />
              <br />
              Σε περίπτωση αντίξοων καιρικών συνθηκών λόγω του απρόβλεπτου αέρα
              της περιοχής, η τοποθεσία θα αλλάξει κατόπιν ενημέρωσης όλων.
            </div>
            <div className="frcc google_link_row">
              <img
                className="google_maps_icon"
                src="../images/google_maps.png"
                alt="Google Maps Icon"
              />
              <Link
                to="https://goo.gl/maps/bczGhLicyBwQNtN57?coh=178573&entry=tt"
                className="google_link"
                target="_blank"
              >
                Δείτε την τοποθεσία στο Google maps
              </Link>
            </div>

            <img
              className="pre_wedding_image"
              src="../images/pre_wedding_image.jpg"
              alt="PreWedding Icon"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default PreWedding;
