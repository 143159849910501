import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/accommodation.css";
import { Link } from "react-router-dom";

const Accommodation = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("Accommodation");
    } else {
      setTitle("Διαμονή");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/en/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_accommodation.png"
              alt="Accomodation Icon"
            />
            <div className="page_info">
              Concerning accommodation we would advise you to look for some
              hotel/apartment either in Aigio (city or coast) or in some of the
              neighbouring areas (Loggos, Selianitika). <br />
              <br />
              Some hotel suggestions are the following:
            </div>
            <div className="hotel_list">
              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://www.harmony-apartments.com"
                    className="hotel_link"
                    target="_blank"
                  >
                    Harmony Hotel Apartments
                  </Link>
                </div>
                <div className="hotel_area">Loggos</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://nikolatos-apartments.gr/"
                    className="hotel_link"
                    target="_blank"
                  >
                    Nikolatos apartments
                  </Link>
                </div>
                <div className="hotel_area">Loggos</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://www.hotelplaz.gr"
                    className="hotel_link"
                    target="_blank"
                  >
                    Plaz Hotel
                  </Link>
                </div>
                <div className="hotel_area">Selianitika</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="http://hotelkanelli.gr"
                    className="hotel_link"
                    target="_blank"
                  >
                    Kanelli Hotel
                  </Link>
                </div>
                <div className="hotel_area">Selianitika</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://amario.gr/en/amario-2/"
                    className="hotel_link"
                    target="_blank"
                  >
                    Amario Suites Hotel
                  </Link>
                </div>
                <div className="hotel_area">Coastal Aigio</div>
              </div>
              <hr className="w100" />
            </div>
            <img
              className="accommodation_image"
              src="../images/accommodation_image.jpg"
              alt="Accomodation Icon"
            />
          </div>
        </div>
      ) : (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/gr/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_accommodation.png"
              alt="Accomodation Icon"
            />
            <div className="page_info">
              Για τη διαμονή προτείνουμε να αναζητήσετε κάποιο
              ξενοδοχείο/διαμέρισμα είτε στο Αίγιο (πόλη ή παραλία) είτε στους
              διπλανούς παραλιακούς οικισμούς (Λόγγος, Σελιανίτικα). <br />
              <br />
              Κάποια ξενοδοχεία προτεινόμενα από εμάς είναι τα εξής:
            </div>
            <div className="hotel_list">
              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://www.harmony-apartments.com"
                    className="hotel_link"
                    target="_blank"
                  >
                    Harmony Hotel Apartments
                  </Link>
                </div>
                <div className="hotel_area">Λόγγος</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://nikolatos-apartments.gr/"
                    className="hotel_link"
                    target="_blank"
                  >
                    Nikolatos apartments
                  </Link>
                </div>
                <div className="hotel_area">Λόγγος</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://www.hotelplaz.gr"
                    className="hotel_link"
                    target="_blank"
                  >
                    Plaz Hotel
                  </Link>
                </div>
                <div className="hotel_area">Σελιανίτικα</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="http://hotelkanelli.gr"
                    className="hotel_link"
                    target="_blank"
                  >
                    Kanelli Hotel
                  </Link>
                </div>
                <div className="hotel_area">Σελιανίτικα</div>
              </div>

              <hr className="w100" />
              <div className="frcc w100">
                <div className="hotel_name">
                  <Link
                    to="https://amario.gr/en/amario-2/"
                    className="hotel_link"
                    target="_blank"
                  >
                    Amario Suites Hotel
                  </Link>
                </div>
                <div className="hotel_area">Παραλία Αιγίου</div>
              </div>
              <hr className="w100" />
            </div>
            <img
              className="accommodation_image"
              src="../images/accommodation_image.jpg"
              alt="Accomodation Icon"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Accommodation;
