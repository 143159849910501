import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/transportation.css";
import { Link } from "react-router-dom";

const Transportation = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("Wedding Transportation");
    } else {
      setTitle("Μετακίνηση Γάμου");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/en/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_transpotation.png"
              alt="Transportation Icon"
            />
            <div className="page_info">
              Reaching Ktima Panagiotopoulou by car from the nearby areas is
              fast and easy. The venue also offers its own designated free
              parking space for all guests. <br />
              <br />
              For guests that would like to reach the venue without a car,
              transportation by designated vehicles after request will be
              arranged; return from the venue as well. You can fill in your
              preference in the RSVP form or/and contact us directly. More
              information concerning the specific timetable of transportation
              will follow the last week of June.
            </div>

            <img
              className="transpotation_image"
              src="../images/transpotation_image.jpg"
              alt="Transportation"
            />
          </div>
        </div>
      ) : (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/gr/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_transpotation.png"
              alt="Transportation Icon"
            />
            <div className="page_info">
              Η μετακίνηση προς το κτήμα Παναγιωτόπουλου οδικώς από τους
              διπλανούς οικισμούς είναι σύντομη και άνετη. Το κτήμα επίσης
              διαθέτει δικό του περιφραγμένο χώρο ελεύθερης στάθμευσης για τους
              καλεσμένους.
              <br />
              <br />
              Για τους καλεσμένους οι οποίοι θα ήθελαν να μεταβούν χωρίς όχημα
              στο κτήμα, θα οργανωθεί συντονισμένη μετακίνηση με μισθωμένα
              οχήματα κατόπιν συνεννόησης, με τη δυνατότητα φυσικά της
              επιστροφής παρομοίως. Συμπληρώστε την προτίμησή σας στην
              ενημερωτική φόρμα ή/και επικοινωνήστε μαζί μας άμεσα. Περισσότερες
              πληροφορίες σχετικά με τα ακριβή ωράρια της μετακίνησης θα
              ακολουθήσουν την τελευταία εβδομάδα του Ιουνίου.
            </div>

            <img
              className="transpotation_image"
              src="../images/transpotation_image.jpg"
              alt="Transportation"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Transportation;
