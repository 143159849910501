import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/menu.css";
import { Link } from "react-router-dom";

const Accommodation = ({ language, setTitle }) => {
  useEffect(() => {
    setTitle("Menu");
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{
        width: 0,
        opacity: 0,
        transitionDuration: 0.1,
      }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: 0, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc menu-container">
          <div className="tulip-container fccc">
            <img className="tulip" src="../images/tulip.png" alt="Tulip" />
          </div>
          <div className="fccs menu">
            <Link to="/en/invitation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_invitation.png"
                alt="Invitation icon"
              />
              <h2 className="menu_word_en">Invitation</h2>
            </Link>
            <Link to="/en/venue" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_venue.png"
                alt="Menu Icon"
              />
              <h2 className="menu_word_en">The Venue</h2>
            </Link>
            <Link to="/en/accommodation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_accommodation.png"
                alt="Accomodation Icon"
              />
              <h2 className="menu_word_en">Accommodation</h2>
            </Link>
            <Link to="/en/pre-wedding" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_pre_wedding.png"
                alt="Pre Wedding Icon"
              />
              <h2 className="menu_word_en">Pre-Wedding Party</h2>
            </Link>
            <Link to="/en/transportation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_transpotation.png"
                alt="Transportation Icon"
              />
              <h2 className="menu_word_en">Wedding Transpotation</h2>
            </Link>
            <Link to="/en/rsvp" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_rsvp.png"
                alt="RSVP Icon"
              />
              <h2 className="menu_word_en">RSVP</h2>
            </Link>
          </div>
          <div className="frcc language_line">
            <Link to="/gr/menu" className="language_link">
              GR
            </Link>{" "}
            |
            <Link
              to="/en/menu"
              className="language_link selected_language_link"
            >
              EN
            </Link>
          </div>
          <div className="lucidity">
            © 2023 Websites by{" "}
            <Link
              to="https://www.lucidity.gr/"
              className="lucidity_link"
              target="_blank"
            >
              Lucidity
            </Link>
          </div>
        </div>
      ) : (
        <div className="background-color fccc menu-container">
          <div className="tulip-container fccc">
            <img className="tulip" src="../images/tulip.png" alt="Tulip" />
          </div>
          <div className="fccs menu">
            <Link to="/gr/invitation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_invitation.png"
                alt="Menu"
              />
              <h2 className="menu_word_gr">Προσκλητήριο</h2>
            </Link>
            <Link to="/gr/venue" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_venue.png"
                alt="Venue Icon"
              />
              <h2 className="menu_word_gr">Το Κτήμα</h2>
            </Link>
            <Link to="/gr/accommodation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_accommodation.png"
                alt="Accomodation Icon"
              />
              <h2 className="menu_word_gr">Διαμονή</h2>
            </Link>
            <Link to="/gr/pre-wedding" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_pre_wedding.png"
                alt="Pre Wedding Icon"
              />
              <h2 className="menu_word_gr">Προεόρτια</h2>
            </Link>
            <Link to="/gr/transportation" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_transpotation.png"
                alt="Transportation Icon"
              />
              <h2 className="menu_word_gr">Μετακίνηση Γάμου</h2>
            </Link>
            <Link to="/gr/rsvp" className="frsc menu_row link">
              <img
                className="menu_icon"
                src="../images/menu_rsvp.png"
                alt="RSVP Icon"
              />
              <h2 className="menu_word_gr">Επιβεβαίωση Παρουσίας</h2>
            </Link>
          </div>
          <div className="frcc language_line">
            <Link
              to="/gr/menu"
              className="language_link selected_language_link"
            >
              GR
            </Link>{" "}
            |
            <Link to="/en/menu" className="language_link">
              EN
            </Link>
          </div>
          <div className="lucidity">
            © 2023 Websites by{" "}
            <Link
              to="https://www.lucidity.gr/"
              className="lucidity_link"
              target="_blank"
            >
              Lucidity
            </Link>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Accommodation;
