import React from "react";

const alertStyle = {
  backgroundColor: "#6ce2ad",
  color: "white",
  borderRadius: "2px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0 8px 12px 0 rgba(0,0,0,0.3)",
  width: "300px",
  boxSizing: "border-box",
  fontSize: "11px",
  position: "relative",
};

const contentWrapperStyle = {
  padding: "10px 10px 10px 10px",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const messageStyle = {
  flex: 3,
  textAlign: "center",
  width: "100%",
  fontSize: "18px",
  fontFamily: "'Raleway', sans-serif",
};

const AlertTemplate = ({ message, options, style, close }) => (
  <div style={{ ...alertStyle, ...style }}>
    <div style={contentWrapperStyle}>
      <div style={messageStyle}>{message}</div>
    </div>
  </div>
);

export default AlertTemplate;
