import { motion } from "framer-motion";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Index = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("Peggy & Tasos");
    } else {
      setTitle("Πέγκυ & Τάσος");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="background-color fccc"
    >
      <div className="background-color pc_background">
        <Link to={`/${language}/menu`} className="index-background">
          <img className="ul" src="images/upper-left.png" alt="Upper Left" />
          <div className="central fccc">
            <img className="c" src="images/tulip.png" alt="Tulip" />
            <img className="names" src="images/names.png" alt="Names" />
          </div>
          <img className="lr" src="images/lower-right.png" alt="Low Right" />
        </Link>
      </div>
    </motion.div>
  );
};

export default Index;
