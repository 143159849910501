import axios from "./axios";

const urls = {
  create_rsvp_response: "/create_rsvp_response",
};
const unauthorizedHeaders = {
  headers: { "Content-Type": "application/json" },
};

export const createRSVPResponse = async (data) => {
  try {
    const response = await axios.post(
      urls.create_rsvp_response,
      JSON.stringify(data),
      unauthorizedHeaders
    );
    if (response && response.data) return response.data;
  } catch (err) {
    console.log("error", err);
  }
  return null;
};
