import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import "../css/rsvp.css";
import { createRSVPResponse } from "../api/backendCalls";

// texts contains the different texts between english and greek versions
const texts = {
  en: {
    will_be_there: "I will be there!",
    will_not_be_there: "Unfortunately, I will not make it",
    name_label: "Name",
    will_they_require_transportation:
      "I will require transportation to the venue",
    additional_info: "Additional information (e.g. extra guests)",
    submit_value: "Submit",
    goodbye:
      "We will be very glad to have you with us and dance together until the morning!",
    error_text: "Please provide all fields.",
    response_submitted: "Your response is submitted. Thank you!",
  },
  gr: {
    will_be_there: "Θα είμαι και εγώ εκεί!",
    will_not_be_there: "Δυστυχώς δεν θα τα καταφέρω.",
    name_label: "Όνομα",
    will_they_require_transportation: "Θα χρειαστώ μετάβαση στο κτήμα.",
    additional_info: "Διευκρινίσεις (π.χ. επιπλέον άτομα)",
    submit_value: "Υποβολή",
    goodbye:
      "Θα χαρούμε πολύ να είστε μαζί μας και να χορέψουμε μέχρι το πρωί!",
    error_text: "Παρακαλούμε συμπληρώστε τις απαραίτητες πληροφορίες.",
    response_submitted: "Η απάντησή σας καταγράφηκε. Ευχαριστούμε!",
  },
};

const Rsvp = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("RSVP");
    } else {
      setTitle("Επιβεβαίωση Παρουσίας");
    }
  }, [language, setTitle]);

  // form fields
  const [willTheyCome, setWillTheyCome] = useState(null);
  const [name, setName] = useState("");
  const [plusPeople, setPlusPeople] = useState(0);
  const [willTheyRequireTransportation, setWillTheyRequireTransportation] =
    useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");

  // form validations
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    setError(false);
    if (willTheyCome === true) {
      if (
        name !== "" &&
        plusPeople !== null &&
        willTheyRequireTransportation !== ""
      ) {
        setIsSubmitAllowed(true);
      } else {
        setIsSubmitAllowed(false);
      }
    } else {
      if (name !== "") {
        setIsSubmitAllowed(true);
      } else {
        setIsSubmitAllowed(false);
      }
    }
  }, [
    willTheyCome,
    name,
    plusPeople,
    willTheyRequireTransportation,
    additionalInfo,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isSubmitAllowed) {
      setError(true);
      return;
    }

    setIsLoading(true);
    let err;
    if (!willTheyCome) {
      err = createRSVPResponse({
        will_they_come: willTheyCome,
        name: name,
        additional_information: additionalInfo,
      });
    } else {
      err = createRSVPResponse({
        will_they_come: willTheyCome,
        name: name,
        additional_information: additionalInfo,
        plus_people: plusPeople,
        will_they_require_transportation: willTheyRequireTransportation,
      });
    }

    if (err !== null) {
      setSubmitSuccess(true);
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "gr" || language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to={"/" + language + "/menu"}>
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          {submitSuccess ? (
            <div className="fccc w100">
              <img
                className="page_icon"
                src="../images/menu_rsvp.png"
                alt="Menu RSVP"
              />
              <div className="fccc form_container">
                <div className="fccc goodbye_container">
                  <div className="goodbye">
                    {texts[language]["response_submitted"]}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="fccc w100">
              <img
                className="page_icon"
                src="../images/menu_rsvp.png"
                alt="Menu RSVP"
              />
              <div className="fccc form_container">
                <form className="fcsc form">
                  <label className="form-control">
                    <input
                      className="radio"
                      type="radio"
                      name="attendance"
                      checked={willTheyCome === true}
                      onChange={() => setWillTheyCome(true)}
                    />
                    {texts[language]["will_be_there"]}
                  </label>
                  <br />
                  <label className="form-control">
                    <input
                      className="radio"
                      type="radio"
                      name="attendance"
                      checked={willTheyCome === false}
                      onChange={() => setWillTheyCome(false)}
                    />
                    {texts[language]["will_not_be_there"]}
                  </label>
                  <br />

                  {willTheyCome !== null ? (
                    <>
                      <label htmlFor="name">
                        {texts[language]["name_label"]}
                      </label>
                      <br />
                      <input
                        className="text_input"
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <br />

                      {willTheyCome ? (
                        <>
                          <label className="form-control">
                            <input
                              className="radio"
                              type="radio"
                              name="extra-guests"
                              checked={plusPeople === 1}
                              onChange={() => setPlusPeople(1)}
                            />
                            +1
                          </label>
                          <br />
                          <label className="form-control">
                            <input
                              className="radio"
                              type="radio"
                              name="extra-guests"
                              checked={plusPeople === 2}
                              onChange={() => setPlusPeople(2)}
                            />
                            +2
                          </label>
                          <br />

                          <label
                            htmlFor="checkbox_will_they_require_transportation"
                            className="form-control"
                          >
                            <input
                              type="checkbox"
                              name="checkbox_will_they_require_transportation"
                              id="checkbox_will_they_require_transportation"
                              value={willTheyRequireTransportation}
                              onChange={(e) =>
                                setWillTheyRequireTransportation(e.target.value)
                              }
                            />
                            {
                              texts[language][
                                "will_they_require_transportation"
                              ]
                            }
                          </label>
                          <br />
                        </>
                      ) : null}

                      <label
                        htmlFor="additional_info"
                        className="additional_info_label"
                      >
                        {texts[language]["additional_info"]}
                      </label>
                      <br />
                      <textarea
                        className="text_input text_area"
                        type="text"
                        id="additional_info"
                        name="additional_info"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                      ></textarea>
                      <br />
                    </>
                  ) : null}

                  {error ? (
                    <p className="error_p">{texts[language]["error_text"]}</p>
                  ) : null}

                  {isLoading ? (
                    <div className="fccc w100">
                      <ReactLoading
                        type="spin"
                        color="#bba86b"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <div className="fccc">
                      <input
                        className={
                          "submit_button" +
                          (isSubmitAllowed ? "" : " disabled_button")
                        }
                        type="submit"
                        value={texts[language]["submit_value"]}
                        onClick={(e) => handleSubmit(e)}
                      />
                    </div>
                  )}
                </form>

                <div className="fccc goodbye_container">
                  <div className="goodbye">{texts[language]["goodbye"]}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </motion.div>
  );
};

export default Rsvp;
