import { motion } from "framer-motion";
import { useEffect } from "react";
import "../css/venue.css";
import { Link } from "react-router-dom";

const Venue = ({ language, setTitle }) => {
  useEffect(() => {
    if (language === "en") {
      setTitle("The Venue");
    } else {
      setTitle("Το Κτήμα");
    }
  }, [language, setTitle]);

  return (
    <motion.div
      initial={{ width: 0, transitionDuration: 0.1, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transitionDuration: 0.1 }}
    >
      {language === "en" ? (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/en/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_venue.png"
              alt="Menu Venue Icon"
            />
            <div className="page_info">
              Ktima Panagiotopoulou venue is located on the old national road
              Corinth-Patras, near Lampiri beach, at a distance of 12km from
              Aigio city and 5km from the neighbouring coast of Selianitika.{" "}
              <br />
              <br />
              It is easily accessible by car and there is a designated free
              parking space inside the venue. <br />
              <br />
              The ceremony will start at 19:00, but of course you are welcome to
              join us earlier. The party will follow at the same location.
            </div>
            <div className="image_container">
              <img
                className="venue_image"
                src="../images/venue_image.png"
                alt="Venue Icon"
              />
              <div className="frcc google_link_row">
                <img
                  className="google_maps_icon"
                  src="../images/google_maps.png"
                  alt="Google Maps Icon"
                />
                <Link
                  to="https://goo.gl/maps/g2iuXVrhYw2Xt28n8"
                  className="google_link"
                  target="_blank"
                >
                  See location in Google maps
                </Link>
              </div>
              <div className="image_background frcc">
                <div className="left_background"></div>
                <div className="right_background"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="background-color fccc">
          <div className="frsc w100">
            <Link to="/gr/menu">
              <img
                className="tulip_back"
                src="../images/tulip_back.png"
                alt="Tulip"
              />
            </Link>
          </div>
          <div className="fccc">
            <img
              className="page_icon"
              src="../images/menu_venue.png"
              alt="Menu Venue Icon"
            />
            <div className="page_info">
              Το κτήμα Παναγιωτόπουλου βρίσκεται επάνω στην παλιά εθνική οδό
              Κορίνθου-Πατρών, στο ύψος της παραλίας Λαμπιρίου, σε απόσταση 12
              χμ από την πόλη του Αιγίου και 5 χμ από τη γειτονική παραλία των
              Σελιανιτίκων.
              <br />
              <br />
              Είναι εύκολα προσβάσιμο με αμάξι και υπάρχει περιφραγμένος χώρος
              ελεύθερης στάθμευσης εντός του κτήματος. <br />
              <br />Η τελετή θα ξεκινήσει στις 19:00, αλλά φυσικά όλοι είστε
              καλοδεχούμενοι και νωρίτερα. Το πάρτυ θα ακολουθήσει στην ίδια
              τοποθεσία.
            </div>
            <div className="image_container">
              <img
                className="venue_image"
                src="../images/venue_image.png"
                alt="Venue Icon"
              />
              <div className="frcc google_link_row">
                <img
                  className="google_maps_icon"
                  src="../images/google_maps.png"
                  alt="Google Maps Icon"
                />
                <Link
                  to="https://goo.gl/maps/g2iuXVrhYw2Xt28n8"
                  className="google_link"
                  target="_blank"
                >
                  Δείτε την τοποθεσία στο Google maps
                </Link>
              </div>
              <div className="image_background frcc">
                <div className="left_background"></div>
                <div className="right_background"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Venue;
